<template>
  <header
    class="page-header"
    :class="{
      'menu-visible': menuSwitch
    }"
  >
    <div
      class="page-header__inner"
      :class="{
        '--no-logo': !logo
      }"
    >
      <router-link
        tag="div"
        :to="{
          path: '/'
        }"
        class="logo"
        v-if="logo"
      ></router-link>
      <div class="menu-bt" @click="menuShow">
        <div class="menu-icon">
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div class="menu-text">
          <p>menu</p>
          <p>close</p>
        </div>
      </div>
    </div>
    <div
      class="page-sub-menu-container"
      :style="{
        height: $isMobile ? `${$mobileScreenHeight}px` : '100vh'
      }"
    >
      <div class="menu-container__lists">
        <div class="list__item" v-for="(menu, index) in subMenus" :key="index">
          <router-link
            class="item__link"
            :to="{
              path: menu.url
            }"
            :data-index="`0${index + 1}`"
          >
            <span class="item__title">
              <span
                class="item__char"
                :class="[letter === ' ' ? 'space' : '']"
                v-for="(letter, l) in parseName(menu.name)"
                :key="l"
                >{{ letter }}</span
              >
            </span>
            <span class="item__title">
              <span
                class="item__char"
                :class="[letter === ' ' ? 'space' : '']"
                v-for="(letter, l) in parseName(menu.name)"
                :key="l"
                >{{ letter }}</span
              >
            </span>
          </router-link>
        </div>
      </div>
      <div class="menu__bottom-info">
        © Copyright {{ new Date().getFullYear() }} Shanghai F·G Studio
        Industrial Co., Ltd
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "page-header",
  props: {
    logo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      menuSwitch: false,
      subMenus: [
        {
          name: "F·G Studio",
          url: "/about"
        },
        {
          name: "Projects",
          url: "/project-type"
        },
        {
          name: "Awards+News",
          url: "/awards"
        },
        {
          name: "Jobs",
          url: "/join"
        },
        {
          name: "Contact",
          url: "/contact"
        }
      ]
    };
  },
  watch: {
    $route: {
      handler() {
        this.menuSwitch = false;
      },
      immediate: true
    },
    menuSwitch(val) {
      if (val) {
        document.body.classList.add("menu-visible");
      } else {
        document.body.classList.remove("menu-visible");
      }
    }
  },
  methods: {
    parseName(val) {
      return val.trim().split("");
    },
    menuShow() {
      this.menuSwitch = !this.menuSwitch;
    }
  }
};
</script>

<style></style>
