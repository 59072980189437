import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "../store";
import { IEVersion } from "@/libs/tools";
import "@/plugins/element.js";
import "@/assets/scss/demo1.scss";
import "nprogress/nprogress.css";
import bowser from "bowser";
import request from "@/api/request";

Vue.config.productionTip = false;
Vue.prototype.$isMobile = bowser.mobile;
Vue.prototype.$mobileScreenHeight = window.innerHeight;
Vue.prototype.$info = {
  icp: "沪ICP备18021782号"
};
Vue.prototype.$request = request;

const ieVersion = IEVersion();

if (ieVersion === -1) {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
} else {
  const hintContainer = document.getElementById("browser-ie-hint-container");
  hintContainer.style.display = "block";
  hintContainer.querySelector(".version").innerHTML = ieVersion;
}
