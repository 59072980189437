<template>
  <footer class="page-footer">
    <div class="footer__inner">
      <template v-if="!$isMobile">
        <p>
          © Copyright {{ new Date().getFullYear() }} Shanghai F·G Studio
          Industrial Co., Ltd 上海馥高实业有限公司 网站版权所有 |
          <a href="https://beian.miit.gov.cn/" target="_blank">{{
            $info.icp
          }}</a>
        </p>
        <p>
          <a href="https://www.gooob.cn" target="_blank"
            >Web Design: Gooobrand</a
          >
        </p>
      </template>
      <template v-else>
        <p>
          © Copyright {{ new Date().getFullYear() }} Shanghai F·G Studio
          上海馥高 网站版权所有
        </p>
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank">{{
            $info.icp
          }}</a>
        </p>
      </template>
    </div>
  </footer>
</template>

<script>
export default {
  name: "page-footer"
};
</script>

<style></style>
