var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"page-header",class:{
    'menu-visible': _vm.menuSwitch
  }},[_c('div',{staticClass:"page-header__inner",class:{
      '--no-logo': !_vm.logo
    }},[(_vm.logo)?_c('router-link',{staticClass:"logo",attrs:{"tag":"div","to":{
        path: '/'
      }}}):_vm._e(),_c('div',{staticClass:"menu-bt",on:{"click":_vm.menuShow}},[_vm._m(0),_vm._m(1)])],1),_c('div',{staticClass:"page-sub-menu-container",style:({
      height: _vm.$isMobile ? (_vm.$mobileScreenHeight + "px") : '100vh'
    })},[_c('div',{staticClass:"menu-container__lists"},_vm._l((_vm.subMenus),function(menu,index){return _c('div',{key:index,staticClass:"list__item"},[_c('router-link',{staticClass:"item__link",attrs:{"to":{
            path: menu.url
          },"data-index":("0" + (index + 1))}},[_c('span',{staticClass:"item__title"},_vm._l((_vm.parseName(menu.name)),function(letter,l){return _c('span',{key:l,staticClass:"item__char",class:[letter === ' ' ? 'space' : '']},[_vm._v(_vm._s(letter))])}),0),_c('span',{staticClass:"item__title"},_vm._l((_vm.parseName(menu.name)),function(letter,l){return _c('span',{key:l,staticClass:"item__char",class:[letter === ' ' ? 'space' : '']},[_vm._v(_vm._s(letter))])}),0)])],1)}),0),_c('div',{staticClass:"menu__bottom-info"},[_vm._v(" © Copyright "+_vm._s(new Date().getFullYear())+" Shanghai F·G Studio Industrial Co., Ltd ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-icon"},[_c('i'),_c('i'),_c('i')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-text"},[_c('p',[_vm._v("menu")]),_c('p',[_vm._v("close")])])}]

export { render, staticRenderFns }