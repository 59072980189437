import Vue from "vue";
import VueRouter from "vue-router";
import bowser from "bowser";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../pages/home.vue"),
    meta: {
      isShowFooter: false,
      isWhiteHeader: true
    }
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../pages/about.vue"),
    meta: {
      isShowFooter: true
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../pages/contact.vue"),
    meta: {
      isShowFooter: false
    }
  },
  {
    path: "/projects/:type",
    name: "projects",
    component: () => import("../pages/projects.vue"),
    meta: {
      isShowFooter: true
    },
    props: true
  },
  {
    path: "/project-type",
    name: "project-type",
    component: () => import("../pages/project-type.vue"),
    meta: {
      isShowFooter: false,
      isWhiteHeader: true && !bowser.mobile
    }
  },
  {
    path: "/project/:id",
    name: "project",
    component: () => import("../pages/project-detail.vue"),
    meta: {
      isShowFooter: true,
      isShowLogo: true
    },
    props: true
  },
  {
    path: "/join",
    name: "join",
    component: () => import("../pages/join.vue"),
    meta: {
      isShowFooter: true
    }
  },
  {
    path: "/news",
    name: "news-list",
    component: () => import("../pages/news.vue"),
    meta: {
      isShowFooter: true
    }
  },
  {
    path: "/news/:id",
    name: "news-detail",
    component: () => import("../pages/news-detail.vue"),
    props: true,
    meta: {
      isShowFooter: true
    }
  },
  {
    path: "/awards",
    name: "awards-list",
    component: () => import("../pages/awards.vue"),
    meta: {
      isShowFooter: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "development" ? "/home" : process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  }
});

export default router;
