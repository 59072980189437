<template>
  <div
    id="app"
    :class="{
      'bg-yellow': $route.name === 'awards-list'
    }"
  >
    <page-header
      :logo="isShowLogo"
      :class="[$route.meta.isWhiteHeader ? 'theme--white' : '']"
    ></page-header>
    <div class="page-main-body">
      <router-view></router-view>
    </div>
    <page-footer v-if="isShowFooter"></page-footer>
  </div>
</template>

<script>
import PageHeader from "../components/demo3-header";
import PageFooter from "../components/footer";
import { pageInit, pageLoading, pageLoaded } from "../assets/utils/common.js";
import { mapState } from "vuex";
export default {
  name: "demo-1",
  components: {
    PageHeader,
    PageFooter
  },
  computed: {
    ...mapState({
      pageStatus: "loading"
    }),
    isShowFooter() {
      return this.$route.meta.isShowFooter;
    },
    isShowLogo() {
      return this.$route.meta.isShowLogo === undefined
        ? true
        : this.$route.meta.isShowLogo;
    }
  },
  watch: {
    $route: function() {
      // pageLoading();
    },
    pageStatus(val) {
      if (val) {
        pageLoading();
      } else {
        pageLoaded();
      }
    }
  },
  created() {
    this.$store.dispatch("getInfo");
  },
  mounted() {
    // this.pageInit();
  },
  updated() {
    this.pageInit();
  },
  methods: {
    pageInit() {
      this.$nextTick(() => {
        if (this.$route.name !== "join") {
          document.body.classList.remove("xxx");
        }
        if (this.$route.name !== "contact") {
          document.body.classList.remove("contact");
        }
        if (this.$route.name !== "about") {
          if (document.querySelector(".page-footer")) {
            document.querySelector(".page-footer").classList.remove("pink");
          }
          document.body.classList.remove("mobile-about");
        }
        if (this.$route.name === "about" || this.$route.name === "join") {
          document.querySelector(".page-footer").classList.add("tu-yellow");
        } else {
          if (document.querySelector(".page-footer")) {
            document
              .querySelector(".page-footer")
              .classList.remove("tu-yellow");
          }
        }
        pageInit();
      });
    }
  }
};
</script>

<style></style>
